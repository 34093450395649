<template>
  <v-app>
    <v-banner
        single-line
        height="500"
        class="children-banner"
    >
      <v-img src="https://h5.ophyer.cn/official_website/banner/tuiguangH5_banner.png" >
        <v-container class="mt-4">
           <v-img src='https://h5.ophyer.cn/official_website/other/H5Logo.png' style="width:112px"></v-img>
        </v-container>
        <v-container color="transparent" class="pa-0 height-per-100 d-flex align-center">
          <v-card color="transparent" class="ml-0" flat>
            <v-card-title class="pa-0 font-size-banner-title">
              <div>
                <div  class="pt-2" style="font-weight: 300;color: #FFFFFF;">产品数字化+虚拟现实 </div>
                <div  class="pt-2" style="font-weight: 300;color: #FFFFFF;">助力企业开启元宇宙运营新时代</div>
              </div>
            </v-card-title>
            <v-card-title class="pa-0 font-size-banner-tip mt-8">
              <div>
                <div class="mb-3" style="font-weight: 400;color: #FFFFFF;">虚拟、超沉浸体验，重塑品牌影响力</div>
              </div>
            </v-card-title>
          </v-card>
        </v-container>
      </v-img>
    </v-banner>

    <!-- 通过万目云你可以做什么？ -->
    <v-card class="warp warp2" :flat="true">
      <div class="public-title">    
        <div>
          <span>通过万目云你可以做什么？</span>
        </div>
        <p>WHAT CAN YOU DO  </p>
      </div>
      <div class="box_two">
        <div class="left">
          <v-card-text class="title">海量模版一键套用 </v-card-text>
          <v-card-text class="title"> 轻松生成趣味互动内容</v-card-text>
          <div class="line"></div>
          <v-card-text class="tip" style="margin-top:21px">
             • AR趣味互动游戏 <br/>
             • 多种AR识别 <br/>
             • VR全景店铺 <br/>
             • VR带看
          </v-card-text>
        </div>
        <div class="right"><img src="https://h5.ophyer.cn/official_website/other/tuiguang01.png" /></div>
      </div>
      <div class="box">
          <div class="left"><img src="https://h5.ophyer.cn/official_website/other/tuiguang02.png" /></div>
          <div class="right">
            <v-card-text class="title">灵活的运营插件  </v-card-text>
            <v-card-text class="title"> 满足更多场景需求</v-card-text>
            <div class="line"></div>
            <v-card-text class="tip" style="margin-top:21px">
               • 转盘抽奖 <br/>
               • 集卡<br/> 
               • 红包<br/> 
               • 砸金蛋等
            </v-card-text>
          </div>
      </div> 
      <div class="box_two">
        <div class="left">
          <v-card-text class="title">实时监控传播效果</v-card-text>
          <v-card-text class="title"> 支持运营决策</v-card-text>
          <div class="line"></div>
          <v-card-text class="tip" style="margin-top:21px">
             • PU、UV、IP数据<br/>
             • 中奖、奖品发放、领取数据<br/>
             • 多维度数据对比
          </v-card-text>
        </div>
        <div class="right"><img src="https://h5.ophyer.cn/official_website/other/tuiguang03.png" /></div>
      </div>
      <div class="box">
          <div class="left"><img src="https://h5.ophyer.cn/official_website/other/tuiguang04.png" /></div>
          <div class="right">
            <v-card-text class="title">线上线强势引流   </v-card-text>
            <v-card-text class="title"> 助力运营转化</v-card-text>
            <div class="line"></div>
            <v-card-text class="tip" style="margin-top:21px">
               • 线上/线下店铺引流<br/>
               • 公众号引流 <br/>
               • 微信群引流
            </v-card-text>
          </div>
      </div>
      <div class="border_Solid"></div> 
    </v-card>
     <!-- 我们的优势 -->
     <div class="app-InvestmentPromotion">
      <v-container class="max-width-1200">
        <div class="public-title" style="margin:62px 0 46px 0">
          <div>
            <span>我们的优势</span>
          </div>
          <p>OUR ADVANTAGE</p>
        </div>
 
        <div class="d-flex justify-center">
          <v-card elevation="0">
            <v-tabs v-model="advantageTab" hide-slider vertical>
                <v-tab
                  v-for="(item,index) in data.advantage"
                  :key="index"
                  :class="['mr-3 justify-start',(data.advantage.length-1) != index?'mb-2':'']"
                  :style="{'background':advantageTab == index?'linear-gradient(90deg, #F5F8FD, #FFFFFF)':'#F5F8FD','border':advantageTab == index?'2px solid #BED3FF':'','box-sizing':'border-box','width':'260px','height':'120px'}"
                >
                  <img :src="item.iconImg" width="50" height="50" />
                  <v-card-title class="font-size-18 px-2" @mouseover="mouseover" @mouseleave="mouseleave">{{ item.tab }}</v-card-title>
                </v-tab>
                <v-tab-item
                  v-for="item in data.advantage"
                  :key="item.tab"
                  transition="fade-transition"
                >
                  <v-card style="box-shadow:none">
                    <!-- {{item}}  -->
                    <!-- <img :src="item.src" alt=""> -->
                    <v-img
                      :src="item.src"
                      class="white--text align-left flex-grow-0"
                      width="790"
                      height="380"
                    >
                    <template v-slot:placeholder>
                            <v-row class="fill-height ma-0" align="center" justify="center">
                              <v-progress-circular
                                indeterminate
                                size="80"
                                color="red lighten-5"
                              ></v-progress-circular>
                            </v-row>
                    </template>
                      <div class="d-flex flex-column  lavbg">
                        <div
                          v-for="(childrenItem, index) in item.titleList"
                          :key="index"
                          :class="[index == 0? 'mt-6':'','ml-6']"
                        >
                          <v-card-title
                            class="white--text font-size-16 font-weight-bold"
                            v-text="childrenItem.title"
                          ></v-card-title>
                          <v-card-text
                            class="white--text font-size-14"
                            v-text="childrenItem.content"
                          ></v-card-text>
                        </div>
                      </div>
                    </v-img>
                  </v-card>
                </v-tab-item>
            </v-tabs>
          </v-card>
        </div>
      </v-container>
    </div>

    <!-- 合作伙伴 -->
    <div class="d-flex justify-center pb-5 warp3" style="margin-top: 60px;background: #F4F8FE;">
      <v-card class="max-width-1200" width="100%" :flat="true" color="transparent">
        <div class="public-title" style="margin-top: 70px;">
          <div>
            <span>合作伙伴</span>
          </div>
          <p>PARTMER</p>
        </div>
        <v-img class="mt-n5" contain src="https://h5.ophyer.cn/official_website/other/home-hzhb3.png"/>
      </v-card>
    </div>

    <!-- 底部 -->
    <div class="warp4">
      <div class="left">
         <v-row style="width:387px">
            <v-img
               alt="logo"
               contain
               src="../../assets/login/logo_lan.png"
               height="62px"
               width="177px"
               style="margin-right:37px"
             />
             <v-img
               alt="logo"
               contain
               src="../../assets/login/wanmuyun-logo.png"
               height="46px"
               width="164px"
              />
          </v-row>
          <p class="mt-8">飞天云动专注智慧运营技术，利用AR/VR引擎、 AI行为算法、云计算等技术能力，为电商、教育、文旅等各垂直行业、企业业务发展赋能。</p>
          <h5 class="mt-15"> 方案咨询：<b>010-63037996</b></h5>
          <h5>周一到周日 9：00-18：00 <br/>
             北京朝阳区广渠路3号竞园艺术中心商8
          </h5>
      </div>
      <div class="right">
          <p class="mt-8">获取完整的AR/VR趣味运营运营方案</p>
          <h5>运营活动无人问津？即刻填写信息获取细分行业、场景的趣味互动落地方案吧</h5>
           <v-form
             ref="form_two"
             v-model="valid"
             lazy-validation
             class="mt-5"
           >
             <v-row justify="space-between">
               <v-col cols="12" md="6">
                  <v-text-field
                    v-model="form_two.name"
                    label="* 您的姓名"
                    :rules="nameRules"
                    color="#FFFFFF"
                    required
                  ></v-text-field>
               </v-col>
               <v-col cols="12" md="6">
                  <v-text-field
                    v-model="form_two.phone"
                    label="* 您的电话"
                    :rules="moblieRules"
                    color="#FFFFFF"
                    required
                  ></v-text-field>
               </v-col>
             </v-row>
             <v-row justify="space-between">
               <v-col cols="24" md="12">
                  <v-text-field
                     v-model="form_two.conpanyName"
                     label="* 您的公司名称"
                     color="#FFFFFF"
                     :rules="comeRules"
                     required
                  ></v-text-field> 
               </v-col>
             </v-row>

             <v-btn 
               :disabled="!valid"
               class="mr-4"
               @click="validate"
             >  
             立即申请
             </v-btn>
           </v-form>         
      </div>
    </div>
    <div class="laster">
      Copyright @ 2021 . Ophyer All right reserved
    </div>
    <!-- 完善信息 -->
    <div class="suspend-btn">
    <div class="hover-group">
      <div class="group-btn">
        
        <v-row class="pa-0" @click.stop="currentHover = 'tryout'">
          <v-img width="50" height="50" contain src="../../../public/img/icons/menu/tryout.png"></v-img>
        </v-row>
        <div class="d-flex justify-center">
          <div style="width: 30%"><v-divider></v-divider></div>
        </div>
        <v-row class="pa-0" @click.stop="go_login" >
          <v-img width="50" height="50" contain src="../../../public/img/icons/menu/follow.png"></v-img>
        </v-row>
        <div class="d-flex justify-center">
          <div style="width: 30%"><v-divider></v-divider></div>
        </div>
        <v-row class="pa-0" @click.stop="currentHover = 'phone'" @mouseenter="currentHover = 'phone'" @mouseleave="currentHover = ''">
          <v-img width="50" height="50" contain src="../../../public/img/icons/menu/phone.png"></v-img>
        </v-row>
      </div>
      <div class="hover-content" @click.stop>
        <transition name="fade" mode="out-in">
          <div class="content-phone"
               @mouseenter="currentHover = 'phone'"
               @mouseleave="currentHover = ''"
               v-show="currentHover === 'phone'">
            <v-card color="transparent" flat>
              <v-row class="pa-0">
                <div class="d-inline width-40">
                  <v-avatar size="40" rounded-2>
                    <v-img contain src="../../../public/img/icons/menu/cooperation.png"></v-img>
                  </v-avatar>
                </div>
                <div class="d-inline ml-3 mt-n3">
                  <v-card color="transparent" flat>
                    <v-card-title class="pa-0 font-size-20 mt-2">
                      商户合作
                    </v-card-title>
                    <v-card-text class="pa-0 mt-3 font-size-16" style="line-height: 1.4">yizhe.han@ophyer.com</v-card-text>
                  </v-card>
                </div>
              </v-row>
              <v-row class="pa-0 mt-8">
                <div class="d-inline width-40">
                  <v-avatar size="40" rounded-2>
                    <v-img width="40" height="40" contain src="../../../public/img/icons/menu/consult.png"></v-img>
                  </v-avatar>
                </div>
                <div class="d-inline ml-3 mt-n3">
                  <v-card color="transparent" flat>
                    <v-card-title class="pa-0 font-size-20 mt-2">
                      SaaS 产品咨询
                    </v-card-title>
                    <v-card-text class="pa-0 mt-3 font-size-16" style="line-height: 1.4">010-63037996</v-card-text>
                  </v-card>
                </div>
              </v-row>
              <v-row class="pa-0 mt-8">
                <div class="d-inline width-40">
                  <v-avatar size="40" rounded-2>
                    <v-img width="40" height="40" contain src="../../../public/img/icons/menu/joinIn.png"></v-img>
                  </v-avatar>
                </div>
                <div class="d-inline ml-3 mt-n3">
                  <v-card color="transparent" flat>
                    <v-card-title class="pa-0 font-size-20 mt-2">
                      招商加盟
                    </v-card-title>
                    <v-card-text class="pa-0 mt-3 font-size-16" style="line-height: 1.4">13916184657</v-card-text>
                  </v-card>
                </div>
              </v-row>
            </v-card>
          </div>
        </transition>
        <transition name="fade" mode="out-in">
          <div class="content-tryout" v-show="currentHover === 'tryout'">
            <v-card color="transparent" flat class="pl-10">
              <v-card-title class="justify-end pb-0 close-btn" @click.stop="currentHover = '';">x</v-card-title>
              <v-card-title class="font-size-18 pa-0 font-weight-bold">完善信息，专属顾问马上联系您</v-card-title>
              <v-card-text class="font-size-14 pa-0 mt-2">苏经理：18511864107</v-card-text>
              <v-form class="pa-0 mt-6">
                <v-container class="pa-0">
                  <v-row class="pa-0">
                    <v-col cols="12" class="pa-0 d-flex">
                      <div>
                        <div class="font-size-14 font-weight-bold">
                          <span class="ml-2" style="color: red;vertical-align: sub;">*</span>
                          姓名
                        </div>
                        <input placeholder="请输入您的姓名" v-model="form.name"/>
                      </div>
                    </v-col>
                    <v-col cols="12" class="pa-0 d-flex mt-5">
                      <div>
                        <div class="font-size-14 font-weight-bold">
                          <span class="ml-2" style="color: red;vertical-align: sub;">*</span>
                          电话
                        </div>
                        <input placeholder="请输入您的电话" v-model="form.phone"/>
                      </div>
                    </v-col>
                    <v-col cols="12" class="pa-0 d-flex mt-5">
                      <div>
                        <div class="font-size-14 font-weight-bold">
                          <span class="ml-2" style="color: red;vertical-align: sub;">*</span>
                          公司名称
                        </div>
                        <input placeholder="请输入您的公司名称" v-model="form.conpanyName"/>
                      </div>
                    </v-col>
                    <v-col cols="12" class="pa-0 d-flex mt-5">
                      <div>
                        <div class="font-size-14 font-weight-bold">地区</div>
                        <input placeholder="请输入您所在的地区" v-model="form.address"/>
                      </div>
                    </v-col>
                    <v-col cols="12" class="pa-0 d-flex mt-7 ">
                      <div class="tryout-btn font-size-14" @click.stop="handleSaveTryout">提交</div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card>
          </div>
        </transition>
        <transition name="fade" mode="out-in">
          <div class="content-follow"
               @mouseenter="currentHover = 'follow'"
               @mouseleave="currentHover = ''"
               v-show="currentHover === 'follow'">
            <v-card color="transparent" flat>
              <v-row class="pa-0 justify-center">
                <v-img contain src="../../../public/img/icons/menu/qrCode.png" width="180" height="180"></v-img>
                <v-card-title class="font-size-16 pa-0">关注飞天云动公众号</v-card-title>
              </v-row>
            </v-card>
          </div>
        </transition>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <div class="hover-btn"
           v-show="officialWebsite.showReturnTip"
           @click.stop="$vuetify.goTo(0, {})">
        <v-img src="../../../public/img/icons/menu/returnTop.png"></v-img>
      </div>
    </transition>
    </div>
    
  </v-app>
</template>

<script>
let _this;
import lavData from '@/static/lavData.js'
import { mapState } from "vuex";
// import qs from 'qs'
export default {
  name: 'Home',
  components: {
    
  },
  computed: {
    ...mapState(["officialWebsite"]),
  },
  data() {
    return {
      data:lavData.InvestmentPromotion,
      // tab: null,
      advantageTab: null,
      timer:null,
      showDialog: false,
      valid: true,
      name: '',
      nameRules: [
        v => !!v || '您的姓名不能为空',
      ],
      moblieRules: [
        v => !!v || '您的电话不能为空',
      ],
      comeRules: [
        v => !!v || '您的公司名称不能为空',
      ],
      form_two:{
        name: "",
        phone: "",
        conpanyName: "",
      },
      currentHover: "",
      form: {
        name: "",
        phone: "",
        conpanyName: "",
        address: ""
      },
    }
  },
  created() {
    let _this = this;
    document.addEventListener("click",()=>{
      _this.showDialog = false;
    })
  },
  mounted() {
    _this = this;
    clearInterval(_this.timer)
    _this.setTimer()
  },
  methods: {
    setTimer:() =>{
      // _this.timer = setInterval(() => {
      //   _this.advantageTab ++
      // },2000)
    },
    mouseover:()=>{
      clearInterval(_this.timer)
    },
    mouseleave:() => {
      _this.setTimer()
    },

    go_login(){
      console.log(_this.$route.query.sourceAgentId,'_this.$route.query.sourceAgentId')
      var sourceAgentId=_this.$route.query.sourceAgentId
      let url = 'http://192.168.1.40:8901?sourceAgentId='+sourceAgentId
      window.open(url, '_blank')
    },
    
    validate () {
     var valid = _this.$refs.form_two.validate()
     if (valid) {
       console.log('验证成功')
     } else {
       console.log('验证失败')
       return false
     }
    },
    on_save() {
      let param = {};
      param.agentId = this.sourceAgentId;
      param.contact = this.form.name;
      param.companyName = this.form.conpanyName;
      param.area = this.form.address;
      param.contactPhone = this.form.phone;
      
      this.$store.dispatch("merchant/ADD_VIP_USERNAME", param)
        .then((res) => {
          console.log('res:'+res)
          if (res==true) {
              this.$message.success("保存客户成功");
              this.$router.push("/merchant/vipList"); 
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    handleSaveTryout: function (){
      if(!this.form.name || !this.form.phone || !this.form.conpanyName){
        this.$lavMessage.warning("请填写必填项！");
        return;
      }
      this.$lavMessage.success("提交成功！");
      this.currentHover = "";
    },
  }
}
</script>

<style lang="scss" scoped>
.banner{
  position: relative;
  ::v-deep .v-banner__wrapper,::v-deep .v-banner__content,::v-deep .v-banner__text{
    padding: 0 !important;
    height: 100%;
  }
}
.height-per-100{
  height: 81%;
}
.dialog{
  z-index: 3;
  width: 550px;
  height: 380px;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  background-color: #FFFFFF;
  box-shadow: 0px 0px 15px 5px rgb(0 114 255 / 10%);
  .close-btn{
    .icon {
      cursor: pointer;
      top: 10px;
      left: 480px;
    }
  }
  ::v-deep .v-card--link {
    cursor: unset;
  }
}
.font-size-banner-title{
  //  font-size: 50px;
   font-family: SourceHanSansCN-Light, SourceHanSansCN;
   font-weight: 300;
   color: #FFFFFF;
  //  line-height: 30px;
}

.warp {
  margin: 0 auto;
  text-align: center;
  width: 100% !important;
  background: #F4F8FE;
  max-width: 1920px !important;
}

.warp2 {
   .v-card__subtitle, .v-card__text ,.text-subtitle-1,.v-card__title{
     line-height: 32px !important;
   }
   .public-title{
    margin-top: 105px  !important;
    line-height: 0px;
    span{
      font-size: 50px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #000000;
    }
    p{
      width: 260px;
      height: 30px;
      font-size: 30px;
      font-family: SourceHanSansCN-Light, SourceHanSansCN;
      font-weight: 300;
      top:61px;
      // color: #000000;
      line-height: 30px;
      letter-spacing: -2px;
    }
  }
  .box {
    width: 1024px;
    margin: 120px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    .left {
      margin-right: 60px;
      margin-left:-160px;
      width: 500px;
      height: 325px;
      box-shadow: 0px 24px 50px 0px rgba(22, 133, 248, 0.2);
      border-radius: 40px;
      img {
        width: 500px;
        height: 325px;
        border-radius: 30px;
      }
    }
   .right {
     text-align: start;
     //font-family: SourceHanSansCN-Regular;
     padding-top: 30px;
     .v-card__text {
       padding: 0;
     }
     .title {
       font-size: 24px !important;
       font-family: SourceHanSansCN-Regular, SourceHanSansCN;
       font-weight: 400;
       color: #000000;
     }
     .tip {
       margin: 21px 0;
       font-size: 16px !important;
       color: rgb(102, 102, 102) !important;
     }
     .text {
       font-size: 14px;
       line-height: 26px;
     }
   }
  }
  .box_two {
    width: 1024px;
    margin: 50px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    .right{
      width: 500px;
      height: 325px;
      box-shadow: 0px 24px 50px 0px rgba(22, 133, 248, 0.2);
      border-radius: 40px;
      img {
        width: 500px;
        height: 325px;
        border-radius: 30px;
      }
    }
   .left {
     text-align: start;
     //font-family: SourceHanSansCN-Regular;
     margin-right: 160px;
     .v-card__text {
       padding: 0;
     }
     .title {
       font-size: 24px !important;
       font-family: SourceHanSansCN-Regular, SourceHanSansCN;
       font-weight: 400;
       color: #000000;
     }
     .tip {
       margin: 21px 0;
       font-size: 16px !important;
       color: rgb(102, 102, 102) !important;
     }
     .text {
       font-size: 14px;
       line-height: 26px;
     }
   }
  }
  .line{
      width: 60px;
      height: 4px;
      background: #000000;
      margin: 28px 0 32px
  }
}
.app-InvestmentPromotion {
  ::v-deep .v-tabs-bar__content {
    display: flex;
    justify-content: center;
  }
  ::v-deep .v-divider--vertical{
    height: 100px;
  }
  .public-title{
    margin-top: 105px  !important;
    line-height: 0px;
    span{
      font-size: 50px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #000000;
    }
    p{
      width: 260px;
      height: 30px;
      font-size: 30px;
      font-family: SourceHanSansCN-Light, SourceHanSansCN;
      font-weight: 300;
      top:61px;
      // color: #000000;
      line-height: 30px;
      letter-spacing: -2px;
    }
  }
}
.lavbg{
  width:50%;
  height: 422px;
  background-size: 100% 100%;
  background-image: url('../../assets/images/InvestmentPromotion/bg.png');
}
::v-deep .v-card__text{
  line-height: 20px!important;
}
.warp3{
  .public-title{
    
    line-height: 0px;
    span{
      font-size: 50px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #000000;
    }
    p{
      width: 260px;
      height: 30px;
      font-size: 30px;
      font-family: SourceHanSansCN-Light, SourceHanSansCN;
      font-weight: 300;
      top:61px;
      // color: #000000;
      line-height: 30px;
      letter-spacing: -2px;
    }
  }
}
::v-deep .warp4{
  width: 100%;
  // height: 450px;
  margin: 60px auto;
  justify-content: center;
  align-items: center;
  display: flex;
  .left{
    width: 560px;
    p,h5{
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #666666;
    line-height: 22px;
    width: 338px;
  }
  }
  .right{
    width: 600px;
    height: 370px;
    background: #2E91F1;
    border-radius: 8px;
    padding-left: 44px;
    p{
      font-size: 24px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 36px;
    }
    h5{
       font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 21px;     
    }
  }
  
  .v-btn:not(.v-btn--round).v-size--default {
    width: 510px;
    height: 50px;
    background: #FFFFFF;
    border-radius: 4px;
    // margin-left: 43px;
    // margin-top: 30px;
  }
  .theme--light.v-label{
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 21px;
    margin-top: -17px;
  }
} 
.suspend-btn{
  position: fixed;
  bottom: 20%;
  right: 20px;
  z-index:1001;
  ::v-deep .v-btn__content{
    white-space: initial;
    display: unset;
    flex: unset;
  }
  .btn-info{
    border-radius: 5px;
  }
  .hover-group{
    position: relative;
    .group-btn {
      width: 52px;
      height: 170px;
      padding-top: 10px;
      border-radius: 25px;
      box-shadow: 0px 0px 10px 2px rgb(0 114 255 / 10%);
      background-color: #FFFFFF;
      cursor: pointer;
    }
    .hover-content{
      position: absolute;
      right: 72px;
      bottom: 0;
      .content-phone{
        width: 280px;
        height: 320px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #FFFFFF;
        border-radius: 10px;
        box-shadow: 0px 0px 10px 2px rgb(0 114 255 / 10%);
      }
      .content-follow{
        width: 200px;
        height: 220px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #FFFFFF;
        border-radius: 10px;
        padding-bottom: 10px;
        box-shadow: 0px 0px 10px 2px rgb(0 114 255 / 10%);
      }
    }
    .content-tryout{
      width: 500px;
      height: 540px;
      display: flex;
      justify-content: center;
      border-radius: 10px;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      background-color: #FFFFFF;
      box-shadow: 0px 0px 15px 5px rgb(0 114 255 / 10%);
      input {
        width: 420px;
        height: 40px;
        border: 1px solid #D5D5D5;
        margin-top: 12px;
        padding: 14px 10px;
      }
      input:focus {//获取焦点
        outline: unset;//边框不用border，用outline
        background: rgba(3, 16, 28, 0);//背景色
      }
      .tryout-btn {
        width: 300px;
        height: 50px;
        background: #2C94F0;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFFFFF;
        letter-spacing:8px;
        cursor: pointer;
      }
      .close-btn{
        color: rgba(0, 0, 0, 0.6) !important;
        cursor: pointer;
      }
    }
  }
  .hover-btn{
    width: 50px;
    height: 50px;
    border-radius: 25px;
    position: fixed;
    bottom: calc(20% - 70px);
    right: 20px;
    z-index:5;
    box-shadow: 0px 0px 10px 2px rgb(0 114 255 / 10%);
    cursor: pointer;
  }
}
.laster{
  width: 100%;
  height: 63px;
  background: #F4F8FE;
  text-align: center;
  padding-top:28px;
  font-size: 12px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #000000;
}
</style>
